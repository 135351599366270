(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/group-list/assets/javascripts/group-list.js') >= 0) return;  svs.modules.push('/components/marketplace/group-list/assets/javascripts/group-list.js');

'use strict';

const GroupList = _ref => {
  let {
    renderItem,
    listData,
    renderOnEmpty
  } = _ref;
  if (listData.length === 0 && renderOnEmpty) {
    return renderOnEmpty();
  }
  return React.createElement(React.Fragment, null, listData.map((data, key) => renderItem(data, data.key || key)));
};
GroupList.defaultProps = {
  listData: []
};
GroupList.propTypes = {
  listData: PropTypes.array,
  renderItem: PropTypes.func.isRequired,
  renderOnEmpty: PropTypes.func
};
setGlobal('svs.components.marketplace.groupList.GroupList', GroupList);

 })(window);